// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Icons Imports
import Magnify from 'mdi-material-ui/Magnify'
import Menu from 'mdi-material-ui/Menu'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import { Button } from '@mui/material'
import { Login } from 'mdi-material-ui'
import { useRouter } from 'next/router'
import { KeyboardEvent, useContext, useEffect, useRef, useState } from 'react'
import { SearchContext } from 'src/@core/context/searchContext'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import { HeaderTitle, StyledLink } from 'src/@core/layouts/components/vertical/navigation/VerticalNavHeader'
import AppLogo from 'src/views/logos/AppLogo'
import themeConfig from 'src/configs/themeConfig'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  isLoggedIn: boolean
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility, isLoggedIn } = props

  // ** Hook
  const { search, setSearch } = useContext(SearchContext)
  const [showSearch, setShowSearch] = useState(false)
  const searchRef = useRef<HTMLInputElement>(null)
  const hiddenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const router = useRouter()

  useEffect(() => {
    if (router.pathname === '/book/search') {
      setSearch(router.query.query as string)
    } else if (search !== '') {
      setSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const onSearchBoxKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      doSearch()
    }
  }
  const doSearch = () => {
    router.push(`/book/search/?query=${search}&pageNumber=1`)
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        {hidden && (
          <IconButton
            color='inherit'
            onClick={toggleNavVisibility}
            sx={{ ml: -2.75, ...(hiddenSm ? {} : { mr: 3.5 }) }}
          >
            <Menu />
          </IconButton>
        )}
        {hidden && !showSearch && (
          <Box mr={6}>
            <StyledLink href='/book/discover/best/1/'>
              <AppLogo />
              <HeaderTitle sx={{ ml: 3, fontSize: '2.5rem', fontFamily: 'Island Moments' }}>
                {themeConfig.templateName}
              </HeaderTitle>
            </StyledLink>
          </Box>
        )}
        {(!hiddenSm || showSearch) && (
          <TextField
            size='small'
            sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 }, flexGrow: 1, maxWidth: 500 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Magnify fontSize='small' />
                </InputAdornment>
              )
            }}
            value={search}
            onChange={e => {
              setSearch(e.target.value)
            }}
            onKeyDown={onSearchBoxKeyPress}
            ref={searchRef}
            onBlur={() => setShowSearch(false)}
            autoFocus={hiddenSm ? true : false}
          />
        )}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {hiddenSm && !showSearch && (
          <IconButton
            color='inherit'
            aria-haspopup='true'
            onClick={() => {
              setShowSearch(true)
            }}
          >
            <Magnify fontSize='small' />
          </IconButton>
        )}
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        {isLoggedIn && <NotificationDropdown />}
        {isLoggedIn && <UserDropdown />}
        {!isLoggedIn && (
          <Button variant='contained' onClick={() => router.push('/login/')} startIcon={<Login />}>
            Login
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default AppBarContent
