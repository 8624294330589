// ** MUI Imports
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'

const FooterContent = () => {
  // ** Var
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const router = useRouter()

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography sx={{ mr: 2 }}>
        {`© ${new Date().getFullYear()}, made by `}
        <Link target='_blank' href='#'>
          inknovl
        </Link>
      </Typography>
      {hidden ? null : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 } }}>
          <Link target='_blank' href='mailto:info@inknovl.com'>
            Support
          </Link>
          <Link
            onClick={e => {
              e.preventDefault()
              router.push('/privacy/')
            }}
            href='/privacy/'
          >
            Privacy Policy
          </Link>
          <Link
            onClick={e => {
              e.preventDefault()
              router.push('/terms/')
            }}
            href='/terms/'
          >
            Terms and Conditions
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default FooterContent
