import { useContext, useEffect, useState } from 'react'
import { LocalStorageContext } from '../context/localStorageContext'

const useLocalStorage = <T>(
  key: string,
  initialValue: T | undefined = undefined
): [T | undefined, (v: T | undefined) => void] => {
  const { storage, getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } = useContext(LocalStorageContext)
  const [state, setState] = useState<T | undefined>(initialValue)

  const setValue = (value: T | undefined) => {
    try {
      if (value === null || value === undefined) {
        removeLocalStorageItem(key)

        return
      }

      setLocalStorageItem(key, value)
    } catch (error) {}
  }

  useEffect(() => {
    if (storage[key] !== state) {
      setState(storage[key])
    }
  }, [storage, key, state])

  useEffect(() => {
    setLocalStorageItem(key, getLocalStorageItem(key));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [state, setValue]
}

export default useLocalStorage
