// ** Type import
import { History } from '@mui/icons-material'
import { BookOpenPageVariantOutline, BookSearchOutline, Bookshelf } from 'mdi-material-ui'
import { VerticalNavItemsType } from 'src/@core/layouts/types'
import AutoModeIcon from '@mui/icons-material/AutoMode';

const navigation = (hasUserSession: boolean): VerticalNavItemsType => {
  return [
    {
      title: 'Discover',
      icon: BookSearchOutline,
      path: '/book/discover/best/1/',
      activateIfStartsWithPath: '/book/discover/'
    },
    {
      title: 'Book Roulette',
      icon: AutoModeIcon,
      path: '/book/roulette/'
    },
    {
      title: 'Your Library',
      icon: Bookshelf,
      path: '/user/library/'
    },
    ...(hasUserSession
      ? [
        {
          title: 'Continue Reading',
          icon: History,
          path: '/user/continue-reading/1/',
          activateIfStartsWithPath: '/user/continue-reading/'
        },
        {
          sectionTitle: 'User Area'
        },
        {
          title: 'My Books',
          icon: BookOpenPageVariantOutline,
          path: '/book/list/'
        }
      ]
      : [])
  ]
}

export default navigation
