import { BookData, UserData } from './types'

export const getBookInitialsFromName = (name: string) => {
  const nameList = name.split(' ')
  if (nameList.length > 1) {
    return `${nameList[0].substring(0, 1)}${nameList[nameList.length - 1].substring(0, 1)}`.toUpperCase()
  }

  return name.substring(0, 2).toUpperCase()
}

export const tryOrGet = <T>(func: () => T, defaultValue: T) => {
  try {
    return func()
  } catch {
    return defaultValue
  }
}

export const tryOrGetAsync = async <T>(func: () => T, defaultValue: T) => {
  try {
    return await func()
  } catch {
    return defaultValue
  }
}

export const getUserProfilePicture = (user?: UserData) =>
  !user ? '' : `${process.env.NEXT_PUBLIC_API_URL}/static/user/profile-picture/${user.profilePicture}`

export const getBookCoverImage = (book: BookData, fullURL = false) => book.coverImage
  ? `${fullURL ? process.env.NEXT_PUBLIC_DOMAIN : ''}${process.env.NEXT_PUBLIC_API_URL}/static/book/cover/${book.coverImage}`
  : `${fullURL ? process.env.NEXT_PUBLIC_DOMAIN : ''}/images/misc/book-cover.png`;

export const numberFormatCompact = (value: number) =>
  Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(value)


export const randomNumber = (min: number, max: number)  => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const sendEvent = (eventName: string, data: any) => {
  //@ts-ignore
  gtag('event', eventName, data);
}
