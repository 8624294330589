// ** Next Imports
import Head from 'next/head'
import { Router } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import themeConfig from 'src/configs/themeConfig'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import ThemeComponent from 'src/@core/theme/ThemeComponent'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

// ** Global css styles
import '../../styles/globals.css'
import { ReactElement } from 'react'
import { LocalStorageProvider } from 'src/@core/context/localStorageContext'
import { SearchProvider } from 'src/@core/context/searchContext'
import Script from 'next/script'
import { Adsense } from '@ctrl/react-adsense'
import { Grid } from '@mui/material'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const getLayout = Component.getLayout ?? ((page: ReactElement) => <UserLayout>
    {page}
    <Grid pt={6} spacing={6} container>
      <Grid xs={12} item>
        <Adsense client={process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_ID as string} slot={process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_SLOT as string} responsive={'true'} />
      </Grid>
    </Grid>
  </UserLayout>)

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title key='title'>{`${themeConfig.templateName}`}</title>
        <meta key='keywords' name='keywords' content='book, read, write, ghostwriter' />
        <meta key='viewport' name='viewport' content='initial-scale=1, width=device-width' />

        <meta key='description' name='description' content={`The books you must read`} />
        <meta key='og:description' property="og:description" content={`The books you must read`} />
        <meta key='og:site_name' property="og:site_name" content={`${themeConfig.templateName}`} />

        <meta key='fb:app_id' property="fb:app_id" content="301156096104245" />
        <meta key='twitter:site' name="twitter:site" content="@inknovl" />
        <meta key='twitter:image:alt' name="twitter:image:alt" content={`${themeConfig.templateName} website`} />

        <meta key='og:title' property="og:title" content={`${themeConfig.templateName}`} />
        <meta key='og:type' property="og:type" content="website" />
        <meta key='og:image' property="og:image" content={`${process.env.NEXT_PUBLIC_DOMAIN}/images/featured.png`} />
        <meta key='og:image:alt' name="og:image:alt" content={`${themeConfig.templateName} website`} />
        <meta key='og:url' property="og:url" content={`${process.env.NEXT_PUBLIC_DOMAIN}/`} />
        <meta key='twitter:card' name="twitter:card" content="summary_large_image" />

        <meta key='google-adsense-account' name="google-adsense-account" content={process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_ID}></meta>

        <noscript>
          <img alt='fbpixel' height="1" width="1" style={{ display: "none" }} src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`} />
        </noscript>
      </Head>
      <Script async src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_ID}`} crossOrigin="anonymous"></Script>
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}></Script>
      <Script id='google-analytics'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
      `}
      </Script>
      <Script id='facebook-jssdk'>
        {`
          <!-- Meta Pixel Code -->
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');

          fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
          fbq('track', 'PageView');
          <!-- End Meta Pixel Code -->
      `}
      </Script>
      <LocalStorageProvider>
        <SettingsProvider>
          <SearchProvider>
            <SettingsConsumer>
              {({ settings }) => {
                return <ThemeComponent settings={settings}>{getLayout(<Component {...pageProps} />)}</ThemeComponent>
              }}
            </SettingsConsumer>
          </SearchProvider>
        </SettingsProvider>
      </LocalStorageProvider>
    </CacheProvider>
  )
}

export default App
