// ** React Imports
import { createContext, useState, ReactNode, useContext, useEffect } from 'react'

// ** MUI Imports
import { PaletteMode } from '@mui/material'

// ** ThemeConfig Import
import themeConfig from 'src/configs/themeConfig'

// ** Types Import
import { ThemeColor, ContentWidth } from 'src/@core/layouts/types'
import { LocalStorageContext } from './localStorageContext'

export type Settings = {
  mode: PaletteMode
  themeColor: ThemeColor
  contentWidth: ContentWidth
}

export type SettingsContextValue = {
  settings: Settings
  saveSettings: (updatedSettings: Settings) => void
}

const initialSettings: Settings = {
  themeColor: 'primary',
  mode: themeConfig.mode,
  contentWidth: themeConfig.contentWidth
}

// ** Create Context
export const SettingsContext = createContext<SettingsContextValue>({
  saveSettings: () => null,
  settings: initialSettings
})

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  // ** State
  const localStorageContext = useContext(LocalStorageContext)
  const storedSettings: Settings = localStorageContext.getLocalStorageItem('settings')
  const [settings, setSettings] = useState<Settings>(initialSettings)

  useEffect(() => {
    if (storedSettings && storedSettings.mode !== 'dark') {
      setSettings({ ...settings, mode: storedSettings.mode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const saveSettings = (updatedSettings: Settings) => {
    setSettings(updatedSettings)
    localStorageContext.setLocalStorageItem('settings', updatedSettings)
  }

  return <SettingsContext.Provider value={{ settings, saveSettings }}>{children}</SettingsContext.Provider>
}

export const SettingsConsumer = SettingsContext.Consumer
