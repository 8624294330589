const AppLogo = () => {
  return (
    <svg width={30} height={25} version='1.1' x='0px' y='0px' viewBox='0 0 177.7 216.8'>
      <g transform='translate(0.000000,304.000000) scale(0.100000,-0.100000)'>
        <path
          d='M1332.4,3034.3c-112-7-325-46-409-75c-49-17-138-55-199-85c-406-200-644-510-713-931c-20-121-13-294,15-405
c39-149,63-156,96-27c112,446,385,873,715,1120c75,56,230,153,235,148c3-3-28-27-68-54c-199-136-436-440-603-774
c-157-316-245-577-293-865c-31-193-31-193-15-206c22-18,136-5,219,25c163,58,508,166,608,191c260,63,518,90,745,78
c99-5,112-4,112,10c0,41-136,69-335,69c-151,0-266-13-453-53c-165-34-190-42-720-225c-73-25-135-44-138-41c-5,4,158,320,177,343
c5,6,73,51,150,100c183,113,243,159,363,274c143,138,217,235,291,383c55,109,135,309,135,338c0,8-148,4-212-6c-47-7-31,8,39,40
c88,39,178,97,198,129c9,13,34,93,55,176c25,95,52,176,75,218c19,36,35,74,35,83c0,17-22,31-43,27
C1387.4,3038.3,1360.4,3036.3,1332.4,3034.3z'
        />
      </g>
    </svg>
  )
}

export default AppLogo;
