// ** React Imports
import { createContext, useState, ReactNode } from 'react'

export type SearchValue = {
  search: string
  setSearch: (query: string) => void
}

// ** Create Context
export const SearchContext = createContext<SearchValue>({
  search: '',
  setSearch: () => undefined
})

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  // ** State
  const [search, setSearch] = useState<string>('')

  return <SearchContext.Provider value={{ search, setSearch }}>{children}</SearchContext.Provider>
}

export const SearchValueConsumer = SearchContext.Consumer
