// ** React Imports
import { createContext, useState, ReactNode } from 'react'

export type LocalStorageValue<T> = {
  storage: any
  getLocalStorageItem: (key: string) => T
  setLocalStorageItem: (key: string, value: T) => void
  removeLocalStorageItem: (key: string) => void
}

// ** Create Context
export const LocalStorageContext = createContext<LocalStorageValue<any>>({
  storage: {},
  getLocalStorageItem: () => null,
  setLocalStorageItem: () => null,
  removeLocalStorageItem: () => null
})

export const LocalStorageProvider = ({ children }: { children: ReactNode }) => {
  // ** State
  const [state, setState] = useState<{ [key: string]: any }>({})

  const getLocalStorageItem = (key: string) => {
    try {
      let value = state[key]
      if (!value) {
        value = localStorage.getItem(key)

        return value ? JSON.parse(value) : undefined
      }

      return value;
    } catch (error) {}
  }

  const setLocalStorageItem = (key: string, value: any) => {
    try {
      if (value === undefined || value === null) {
        return removeLocalStorageItem(key);
      }

      // If the passed value is a callback function,
      //  then call it with the existing state.
      const valueToStore = value instanceof Function ? value(state) : value
      localStorage.setItem(key, JSON.stringify(valueToStore))
      setState({ ...state, [key]: valueToStore })
    } catch (error) {}
  }

  const removeLocalStorageItem = (key: string) => {
    try {
      if (state[key]) {
        const newState = { ...state }
        delete newState[key]
        setState(newState)
      }
      localStorage.removeItem(key)
    } catch (error) {}
  }

  return (
    <LocalStorageContext.Provider
      value={{ storage: state, getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem }}
    >
      {children}
    </LocalStorageContext.Provider>
  )
}

export const LocalStorageConsumer = LocalStorageContext.Consumer
